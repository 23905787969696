import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
  en:{
    header: {
      close_session: "Close session",
      profile: "Profile",
      surveys: "Surveys",
      proctoring: "Proctoring",
      close_session_modal: "Are you sure to close session?"
    },
    footer: {
      technical_problems: "Technical problems? Contact us",
      legal_advice: "Legal advise",
      lgdp: "Data protection"
    },
    signin:{
      title: "Welcome!",
      title_backoffice: "T3 - BackOffice",
      subtitle: "We welcome you to <br /> the <span class = 'c-highlighted'> GSG Metrics</span> evaluation platform.",
      subtitle_backoffice: "We welcome you to <br /> the <span class = 'c-highlighted'> GSG Metrics</span> Backoffice evaluation platform.",
      forgot_password: "Forgot password?",
      rescue_password: "Rescue password",
      email_sent: "An email has been sent to your inbox",
      email_not_found: "Email not found",
      change_password: "Change password"
    },
    survey: {
      loading: "Loading...",
      title: "Surveys",
      modules: "Modules",
      in_progress: "In progress",
      finished: "Finished",
      finished_a: "Finished",
      finished_thx: "Finished, thanks!",
      closed: "Closed",
      download_report: "Download Report",
      show_report: "Show Report",
      progress: "Progress",
      module_finished_title: "Keep going!",
      module_finished_subtitle: "You have finished.",
      survey_finished_title: "Great!",
      survey_finished_subtitle: "Survey finished!",
      time_ended: "There is no time left, to continue please click on the next arrow",
      warning: "WARNING",
      time_left: "You have %time% left to finish the test.",
      remember_button: 'Remember that you have to press the submit button <span style="vertical-align: middle"><Arrow width=20 height=20/></span> <span class="o-color--red"><b>before</b> timeout</span>',
      remember_lost: "If not your answer will not be saved",
      languages: "Languages"
    },
    buttons:{
      start: "Start",
      back: "Back",
      continue: "Continue",
      send: "Send",
      cancel: "Cancel",
      accept: "Accept",
      check: "Check",
      refresh: "Refresh"
    },
    errors:{
      unexpected: "An error ocurred",
    },
    profile: {
      title: "Profile",
      subtitle: "You can update your biodata or close session",
      success_title: "Updated successfully!"
    },   
    form: {
      password: "Password",
      password_confirmation: "Password confirmation",
      password_length: "Should be at least 8 characters long",
      password_mismatch: "Passwords mismatch",
      empty: "Can't be blank",
      name: "Name",
      email: "Email", 
      email_not_valid: "Email not valid",
      email_not_changable: "You can not change the email",
      surname: "Last Name",
      lastname: "Last name",
      subject_id: "Subject ID",

      birthdate: "Birthdate",
      gender: "Gender",
      country: "Country",
      language: "Language",
      training: "Training",
      job_position: "Job position",
      personal_identifier: "Personal Identifier Number",
      department: "Department",
      liability: "Managerial responsibilities",
      sector: "Organization sector",
      some_data: "Some data",
      know_you: "Before we start, we need to get to know you a little better:",
      process_password_info: "Please keep the username and password with which you register on the platform as it will be common to all processes",
      language_es: "US Spanish",
      language_en: "US English",
      language_cat: "Catalán",
      language_pt: "Portugués",
      language_de: "Deutsch",
      language_hr: "Croatian",
      gender_men: "Man",
      gender_women: "Women",
      gender_other: "Other",
      gender_0: "Man",
      gender_1: "Women",
      gender_2: "Other",
      mahou_birth: "Edad",
      mahou_antic: "Antigüedad",
      mahou_job_position: "Perfil de puesto",
      mahou_unity: "Unidad de Negocio/Dirección"
    },
    invitation: {
      expired: "Invitación caducada/inválida",
      expired_description: "Por favor, pide un nuevo email de invitación para poder seguir el proceso",
      already_accepted: "Invitación ya aceptada",
      already_accepted_description: "El usuario ya existe, por favor entra en la plataforma con el usuario y contraseña que ya establecistes"
    },
    proctoring:{
      success_description: "We have just verified that your system adequately meets the requirements for remote monitoring. <br/> <br/> Please, when you are ready, press the Start button to start the test.",
      error_title: "It seems we have had a problem, please check the permissions or instructions in your browser and try again.",
      start: {
        title: "We are going to carry out some technical tests!",
        description: "With these tests we will verify that your system meets the technical requirements. <br/> We will verify that the camera and microphone work correctly, checking that image and sound are received. <br/> We will also perform a screen sharing check. <br/> Please, press the continue button and we will guide you through each step.",
        browsers_compatibility: "The <b> supported browsers </b> for proctoring are",
        title_inside: "Whoops! This test requires remote supervision",
        description_inside: "Before starting the test, we will verify that your system meets all the necessary technical requirements. <br/> <br/> Please, press the continue button and we will guide you through the verification"        
      },
      camera_speakers: {
        title: "Camera and microphone",
        description: "We will check that your camera and microphone are working correctly. <br/> <br/> To verify that we receive image and sound, please accept the permission to use the camera and microphone once you press the check button.",
        success: "Camera and microphone, ok!"
      },
      screen_recording: {
        title: "Screen recording",
        description: "Next we will check that you can share the screen with us. <br/> <br/> Please, press the Check button and if you have more than one screen, select where you are going to perform the test and wait until you get the verification message. ",
        success: "Screen recording, ok!"
      },
      success: {
        title: "Perfect!, everything ok.",
        description: "Congratulations, your system meets all the technical requirements necessary to perform remote monitoring. <br/> <br/> Please do not make any changes until you have performed the test.",
        description_inside: "We have just verified that your system adequately meets the requirements for remote monitoring. <br/> <br/> Please, when you are ready, press the Start button to start the test.",
      },
      permissions: {
        title: "Please activate the permissions",
        description: "It seems that we have not been able to activate the services, perhaps you should give the browser permissions within the security section of your system settings"
      }      
    },    
    dictionaries:{
      languages: [["es", "US Spanish"], ["en", "US English"], ["cat", "Catalán"], ["pt", "Portugués"], ["de", "Deutsch"], ["hr", "Croatian"]],
      genders: [[0, "Man"], [1, "Women"], [2, "Other"]],
      sectors: [[0, "Unspecified"], [1, "Industry"], [2, "Service companies"], [3, "Public administration"], [4, "Oil, gas or mining"], [5, "Pharmaceutical or health care"], [6, "Banking and finance"], [7, "Transport and distribution"], [8, "Technologies of information "], [9, "Telecommunications "], [10, "Media "], [11, "Agriculture and fisheries "], [12, "Sales to the public"], [13, "Business"], [14, "Business group"], [15, "Others"]],
      liabilities: [[0, "Unspecified"],[1, "Executive Director"],[2, "Second level managers (you manage other managers)"],[3, "Top-level managers (you direct non-managerial staff)"],[4, "Other managers (with people in your charge)"],[5, "Project manager / Project Manager (without your team)"],[6, "Non-managerial (without people or projects in your charge)"]],
      liabilitys: [[0, "Unspecified"],[1, "Executive Director"],[2, "Second level managers (you manage other managers)"],[3, "Top-level managers (you direct non-managerial staff)"],[4, "Other managers (with people in your charge)"],[5, "Project manager / Project Manager (without your team)"],[6, "Non-managerial (without people or projects in your charge)"]],
      job_positions: [[0, "Unspecified"], [1, "Administrative"], [2, "Professional Services"], [3, "Engineering / Technician", "Finance"], [4, "Human Resources"], [5, "Information technology"], [6, "Operations"], [7, "Marketing"], [8, "Logistics and distribution"], [9, "Production"], [10, "Purchasing "], [11," Quality "], [12," Research and development "], [13," Sales "], [14," Safety "], [15," Others "]],
      trainings: [[0, "Unspecified"], [1, "No formal qualification"], [2, "Elementary studies"], [3, "ESO, School graduate"], [4, "Bachelor, BUP, COU, FP, Professional degrees "], [5," Graduate, Diploma, University degree "], [6," Postgraduate (Master, Doctorate) "], [7," Others "]],
      mahou_births: [[0, "Menos de 30"], [1, "30-35"], [2, "36-45"], [3, "46-55"], [4, "Más de 56"]],
      mahou_antics: [[0, "0-2 años"], [1, "Más de 2 y menos de 5 años"], [2, "Más de 5 y menos de 10 años"], [3, "Más de 10 y menos de 15 años"], [4, "Más de 15 años"]],
      mahou_job_positions: [[0, "Directivos"], [1, "Mandos de Proximidad"], [2, "Mandos Intermedios"], [3, "Gestor"], [4, "Gestor Comercial"], [5, "Técnico"], [6, "Coordinador"], [7, "Administrativo"], [8, "Oficiales"], [9, "Chofer/ Repartidor"]],
      mahou_unitys: [[0, "Dirección Corporativa"], [1, "Distribución Propia"], [2, "Servicios Logísticos"], [3, "Unidad de Negocio Agua"], [4, "Unidad de Negocio España"], [5, "Unidad de Negocio Internacional"]]      
    }   
   },
  es: {
    header: {
      close_session: "Cerrar sesión",
      profile: "Perfil",
      surveys: "Pruebas",
      proctoring: "Proctoring",
      close_session_modal: "¿Estás seguro que deseas cerrar la sesión?"
    },
    signin:{
      title: "¡Bienvenido!",
      title_backoffice: "T3 - BackOffice",
      subtitle: "Te damos la bienvenida a <br />  la plataforma de evaluación de <span class='c-highlighted'> GSG Metrics.</span>",
      subtitle_backoffice: "Te damos la bienvenida al BackOffice <br />de la plataforma de evaluación de <span class='c-highlighted'> GSG Metrics.</span>",
      forgot_password: "¿Has olvidado tu contraseña?",
      forgot_password_title: "Recuperar contraseña",
      email_sent: "Se ha enviado un email a su correo",
      email_not_found: "El email no existe",
      change_password: "Cambiar contraseña"
    },    
    footer: {
      technical_problems: "¿Problemas técnicos? Contáctanos",
      legal_advice: "Aviso legal",
      lgdp: "Protección de datos",
    },        
    survey: {
      loading: "Cargando...",
      title: "Pruebas",
      modules: "Módulos",
      in_progress: "En curso",
      finished: "Finalizada",
      finished_a: "Finalizada",
      finished_thx: "Finalizada, ¡gracias!",
      closed: "Closed",
      download_report: "Descargar Informe",
      show_report: "Ver Informe",
      progress: "Progreso",
      module_finished_title: "¡Sigue así!",
      module_finished_subtitle: "Acabaste",
      survey_finished_title: "¡Genial!",
      survey_finished_subtitle: "¡Prueba finalizada!",
      time_ended: "Ya no queda tiempo, para continuar por favor dale a la flecha de siguiente",
      warning: "AVISO",
      time_left: "Te quedan %time% para terminar la prueba.",
      remember_button: 'Recuerda que tienes que darle al botón de enviar <span style="vertical-align: middle"><Arrow width=20 height=20/></span> <span class="o-color--red"><b>antes</b> de que termine el tiempo</span>',
      remember_lost: "Si no tu respuesta no será guardada",
      languages: "Idiomas"
    },
    buttons:{
      start: "Iniciar",
      back: "Volver",
      continue: "Continuar",
      send: "Enviar",
      cancel: "Cancelar",
      accept: "Aceptar",
      check: "Comprobar",
      refresh: "Recargar"
    },  
    errors:{
      unexpected: "Ha habido un error",
    },
    profile: {
      title: "Perfil",
      subtitle: "Desde aquí puedes actualizar tus datos o cerrar sesión.",
      success_title: "¡Actualizado correctamente!"
    },
    form: {
      password: "Contraseña",
      password_confirmation: "Confirmar contraseña",
      password_length: "Mínimo de 8 carácteres.",
      password_mismatch: "Las contraseñas no coinciden.",
      empty: "Este campo no puede estar vacío",
      email: "Email", 
      email_not_valid: "Email no válido",
      email_not_changable: "No puedes cambiar tu email.",    
      name: "Nombre",
      surname: "Primer apellido",
      lastname: "Segundo apellido",
      subject_id: "Subject ID",
      birthdate: "Año de nacimiento",
      gender: "Género",
      country: "País de residencia",
      language: "Idioma",
      training: "Formación",
      job_position: "Trabajo",
      personal_identifier: "ID Matrícula",
      department: "Departamento",
      liability: "Responsabilidades directivas",
      sector: "Sector de la organización",
      some_data: "Algunos datos",
      know_you: "Antes de empezar, necesitamos conocerte un poco mejor:",
      process_password_info: "Por favor, conserva el nombre de usuario (email) y contraseña con el que te registras en la plataforma ya que será común a todos los procesos",
      language_es: "US Español",
      language_en: "US Inglés",
      language_cat: "Català",
      language_pt: "Portugués",
      language_de: "Alemán",
      language_hr: "Croata",
      gender_men: "Hombre",
      gender_women: "Mujer",
      gender_other: "Otro",
      gender_0: "Hombre",
      gender_1: "Mujer",
      gender_2: "Otro",
      mahou_birth: "Edad",
      mahou_antic: "Antigüedad",
      mahou_job_position: "Perfil de puesto",
      mahou_unity: "Unidad de Negocio/Dirección"

    },
    invitation: {
      expired: "Invitación caducada/inválida",
      expired_description: "Por favor, pide un nuevo email de invitación para poder seguir el proceso",
      already_accepted: "Invitación ya aceptada",
      already_accepted_description: "El usuario ya existe, por favor entra en la plataforma con el usuario y contraseña que ya establecistes"
    },
    proctoring:{
      error_title: "Parece que hemos tenido algún problema, por favor, revisa los permisos o instrucciones en tu navegador y vuelve a intentarlo.",      
      start: {
        title: "¡Vamos a realizar unas pruebas técnicas!",
        description: "Con estas pruebas vamos a verificar que tu sistema cumple con los requerimientos técnicos<br/>Verificaremos que la cámara y micrófono funcionan correctamente comprobando que se recibe imagen y sonido.<br/>También realizaremos una comprobación de compartición de pantalla.<br/>Por favor, pulsa el botón continuar y te guiaremos en cada paso.",
        browsers_compatibility: "Los <b>navegadores compatibles</b> para el proctoring son",
        title_inside: "¡Ups! Esta prueba requiere de supervisión remota",
        description_inside: "Antes de iniciar la prueba vamos a verificar que tu sistema cumple con todos los requerimientos técnicos necesarios.<br/><br/>Por favor, pulsa el botón de continuar y te guiaremos en la comprobación"
      },
      camera_speakers: {
        title: "Cámara y micrófono", 
        description: "Vamos a comprobar que tu cámara y micrófono funcionan correctamente.<br/><br/>Para verificar que recibimos imagen y sonido, por favor acepta los permisos de uso de cámara y micrófono una vez pulses el botón de comprobar.",
        success: "Cámara y micrófono, ¡ok!"
      },
      screen_recording: {
        title: "Grabación de pantalla", 
        description: "A continuación vamos a comprobar que puedes compartir la pantalla con nosotros.<br/><br/>Por favor, pulsa el botón Comprobar y en caso de que tengas más de una pantalla selecciona en la que vas a realizar la prueba y espera hasta que recibas el mensaje de verificación.",
        success: "Grabación de pantalla, ¡ok!"
      },
      success: {
        title: "¡Perfecto!, todo ok.",
        description: "Enhorabuena, tu sistema cumple con todos los requerimientos técnicos necesarios para realizar una supervisión remota.<br/><br/>Por favor, no realices ningún cambio hasta que hayas realizado la prueba.",
        description_inside: "Acabamos de comprobar que tu sistema cumple adecuadamente con los requisitos para realizar la supervisión remota.<br/><br/>Por favor, cuando estés preparado pulsa el botón de Empezar para iniciar la prueba.",
      },
      permissions: {
        title: "Por favor, activa los permisos",
        description: "Parece que no hemos podido activar los servicios, quizás deba dar permisos al navegador dentro de la sección de seguridad de los ajustes de tu sistema"
      }
    },
    dictionaries:{
      languages: [["es", "US Español"], ["en", "US Inglés"], ["cat", "Català"], ["pt", "Portugués"], ["de", "Alemán"],  ["hr", "Croata"]],
      genders: [[0, "Hombre"], [1, "Mujer"], [2, "Otro"]],
      sectors: [[0, "No especificado"], [1, "Industria"], [2, "Empresas de servicio"], [3, "Administración pública"], [4, "Petroleras, gas o minería"], [5, "Farmacéutica o de asistencia sanitaria"], [6, "Banca y finanzas"], [7, "Transportes y distribución"], [8, "Tecnologías de la información"], [9, "Telecomunicaciones"], [10, "Medios de comunicación"], [11, "Agricultura y pesca"], [12, "Venta al público"], [13, "Negocios"], [14, "Grupo empresarial"], [15, "Otros"]],
      liabilities: [[0, "No especificado"],[1, "Director ejecutivo"],[2, "Mandos de segundo nivel (diriges a otros directivos)"],[3, "Mandos de primer nivel (diriges a plantilla no directiva)"],[4, "Otros mandos (con personas a tu cargo)"],[5, "Responsable de proyecto/Project Manager (sin equipo atu cargo)"], [6, "No directivo (sin personas ni proyectos a tu cargo)"]],
      liabilitys: [[0, "No especificado"],[1, "Director ejecutivo"],[2, "Mandos de segundo nivel (diriges a otros directivos)"],[3, "Mandos de primer nivel (diriges a plantilla no directiva)"],[4, "Otros mandos (con personas a tu cargo)"],[5, "Responsable de proyecto/Project Manager (sin equipo atu cargo)"], [6, "No directivo (sin personas ni proyectos a tu cargo)"]],
      job_positions: [[0, "No especificado"], [1, "Administrativo"], [2, "Servicios profesionales"], [3, "Ingeniería / técnico", "Finanzas"], [4, "Recursos Humanos"], [5, "Tecnologías de la información"], [6, "Operaciones"], [7, "Marketing"], [8, "Logística y distribución"], [9, "Producción"], [10, "Compras"], [11, "Calidad"], [12, "Investigación y desarrollo"], [13, "Ventas"], [14, "Seguridad"], [15, "Otros"]],
      trainings: [[0, "No especificado"], [1, "Sin cualificación formal"], [2, "Estudios elementales"], [3, "ESO, Graduado escolar"], [4, "Bachiller, BUP, COU, FP, Grados profesionales"], [5, "Licenciado, Diplomado, Grado universitario"], [6, "Postgrado (Master, Doctorado)"], [7, "Otros"]],
      mahou_births: [[0, "Menos de 30"], [1, "30-35"], [2, "36-45"], [3, "46-55"], [4, "Más de 56"]],
      mahou_antics: [[0, "0-2 años"], [1, "Más de 2 y menos de 5 años"], [2, "Más de 5 y menos de 10 años"], [3, "Más de 10 y menos de 15 años"], [4, "Más de 15 años"]],
      mahou_job_positions: [[0, "Directivos"], [1, "Mandos de Proximidad"], [2, "Mandos Intermedios"], [3, "Gestor"], [4, "Gestor Comercial"], [5, "Técnico"], [6, "Coordinador"], [7, "Administrativo"], [8, "Oficiales"], [9, "Chofer/ Repartidor"]],
      mahou_unitys: [[0, "Dirección Corporativa"], [1, "Distribución Propia"], [2, "Servicios Logísticos"], [3, "Unidad de Negocio Agua"], [4, "Unidad de Negocio España"], [5, "Unidad de Negocio Internacional"]]
    }
   },
  cat: {
    header: {
      close_session: "Tancar sessió",
      profile: "Perfil",
      surveys: "Proves",
      proctoring: "Proctoring",
      close_session_modal: "Segur que vols tancar la sessió?"
    },
    signin:{
      title: "Benvingut!",
      title_backoffice: "T3 - BackOffice",
      subtitle: "Et donem la benvinguda a <br />  la plataforma d'evaluació de <span class='c-highlighted'> GSG Metrics.</span>",
      subtitle_backoffice: "Et donem la benvinguda al BackOffice <br />de la plataforma d'evaluació de <span class='c-highlighted'> GSG Metrics.</span>",
      forgot_password: "Has oblidat la contrasenya?",
      forgot_password_title: "Recuperar contrasenya",
      email_sent: "Hem enviat un email al teu correu",
      email_not_found: "El email no existeix",
      change_password: "Canviar contrasenya"
    },    
    footer: {
      technical_problems: "Problemes tècnics? Contacta'ns",
      legal_advice: "Avís legal",
      lgdp: "Protecció de dades",
    },        
    survey: {
      loading: "Carregant...",
      title: "Proves",
      modules: "Mòduls",
      in_progress: "En curs",
      finished: "Finalitzada",
      finished_a: "Finalitzada",
      finished_thx: "Finalitzada, ¡gràcies!",
      closed: "Tancat",
      download_report: "Descarregar Informe",
      show_report: "Veure Informe",
      progress: "Progrés",
      module_finished_title: "Continua així!",
      module_finished_subtitle: "Has acabat",
      survey_finished_title: "¡Genial!",
      survey_finished_subtitle: "Prova finalitzada!",
      time_ended: "El temps ha acavat, per continuar continua amb la fletxa",
      warning: "AVISO",
      time_left: "Te quedan %time% para terminar la prueba.",
      remember_button: 'Recuerda que tienes que darle al botón de enviar <span style="vertical-align: middle"><Arrow width={20} height={20}/></span> <span class="o-color--red"><b>antes</b> de que termine el tiempo</span>',
      remember_lost: "Si no tu respuesta no será guardada",
      languages: "Llenguatges"      
    },
    buttons:{
      start: "Començar",
      back: "Enrera",
      continue: "Continuar",
      send: "Enviar",
      cancel: "Cancel·lar",
      accept: "Acceptar",
      check: "Comprovar",
      refresh: "Recarregar"
    },  
    errors:{
      unexpected: "Hi ha hagut un error",
    },
    profile: {
      title: "Perfil",
      subtitle: "Desde aquí post actualizar les teves dades o tancar sessió",
      success_title: "Actualitzat correctament!"
    },
    form: {
      password: "Contrasenya",
      password_confirmation: "Confirmar contrasenya",
      password_length: "Mínim 8 carácters.",
      password_mismatch: "Les contrasenyes no coincideixen.",
      empty: "Este campo no pot estar buit",
      email: "Email", 
      email_not_valid: "Email no vàlid",
      email_not_changable: "No pots canviar el teu email.",    
      name: "Nom",
      surname: "Primer cognom",
      lastname: "Segón cognom",
      subject_id: "Subject ID",
      birthdate: "Any naixement",
      gender: "Gènere",
      country: "País de residència",
      language: "Idioma",
      training: "Formació",
      job_position: "Posició",
      personal_identifier: "ID Matrícula",
      department: "Departament",
      liability: "Responsabilitats directives",
      sector: "Sector de l'organizació",
      some_data: "Algunes dades",
      know_you: "Abans de començar, necessitem conèixer-te una mica millor:",
      process_password_info: "Si us plau, conserva el nom d'usuari (email) i contrasenya amb què et registres a la plataforma ja que serà comú a tots els processos",
      language_cat: "Català",
      language_es: "Castellá",
      language_en: "Anglés",
      language_pt: "Portugués",
      language_de: "Alemà",
      language_hr: "Croata",      
      gender_men: "Hombre",
      gender_women: "Mujer",
      gender_other: "Otro",
      gender_0: "Hombre",
      gender_1: "Mujer",
      gender_2: "Otro",
      mahou_birth: "Edad",
      mahou_antic: "Antigüedad",
      mahou_job_position: "Perfil de puesto",
      mahou_unity: "Unidad de Negocio/Dirección"      
    },
    invitation: {
      expired: "Invitación caducada/inválida",
      expired_description: "Por favor, pide un nuevo email de invitación para poder seguir el proceso",
      already_accepted: "Invitación ya aceptada",
      already_accepted_description: "El usuario ya existe, por favor entra en la plataforma con el usuario y contraseña que ya establecistes"
    },
    proctoring:{
      error_title: "Sembla que hem tingut algun problema, si us plau, revisa els permisos o instruccions del teu navegador i torna a intentar-ho.",      
      start: {
        title: "Anem a fer unes proves tècniques!",
        description: "Amb aquestes proves verificarem que el teu sistema compleix els requeriments tècnics.<br/>Verificarem que la càmera i micròfon funcionen correctament comprovant que es rep imatge i so.<br/>També realitzarem una comprovació de compartició de pantalla.<br/ >Si us plau, prem el botó continuar i et guiarem a cada pas.",
        browsers_compatibility: "Els <b>navegadors compatibles</b> per el proctoring són",
        title_inside: "¡Ups! Aquesta prova requereix de supervisió remota",
        description_inside: "Abans d'iniciar la prova verificarem que el teu sistema compleix tots els requeriments tècnics necessaris.<br/><br/>Si us plau, prem el botó de continuar i et guiarem en la comprovació"
      },
      camera_speakers: {
        title: "Càmera i micròfon", 
        description: "Comprovarem que la teva càmera i micròfon funcionen correctament.<br/><br/>Per verificar que rebem imatge i so, si us plau accepta els permisos d'ús de càmera i micròfon un cop prems el botó de comprovar.",
        success: "Càmera i micròfon, ¡ok!"
      },
      screen_recording: {
        title: "Grabació de pantalla", 
        description: "A continuació comprovarem que pots compartir la pantalla amb nosaltres.<br/><br/>Si us plau, prem el botó Comprovar i en cas que tinguis més d'una pantalla selecciona on realitzaràs la prova i espera fins a que rebis el missatge de verificació.",
        success: "Grabació de pantalla, ¡ok!"
      },
      success: {
        title: "¡Perfecte!, tot ok.",
        description: "Enhorabona, el teu sistema compleix tots els requeriments tècnics necessaris per realitzar una supervisió remota.<br/><br/>Si us plau, no facis cap canvi fins que hagis realitzat la prova.",
        description_inside: "Acabem de comprovar que el teu sistema compleix adequadament els requisits per realitzar la supervisió remota.<br/><br/>Si us plau, quan estiguis preparat prem el botó de Començar per iniciar la prova.",
      },
      permissions: {
        title: "Per favor, activa els permisos",
        description: "Sembla que no hem pogut activar els serveis, potser haureu de donar permisos al navegador dins de la secció de seguretat dels ajustaments del vostre sistema"
      }
    },
    dictionaries:{
      languages: [["es", "Castellá"], ["en", "Anglés"], ["cat", "Català"], ["pt", "Portugués"], ["de", "Alemà"], ["hr", "Croata"]],
      genders: [[0, "Home"], [1, "Dona"], [2, "Altre"]],
      sectors: [[0, "No especificat"], [1, "Indùstria"], [2, "Empreses de serveis"], [3, "Administració pública"], [4, "Petroleres, gas o minería"], [5, "Farmacèutica o d'assistencia sanitària"], [6, "Banca i finances"], [7, "Transports i distribució"], [8, "Tecnologies de la informació"], [9, "Telecomunicacions"], [10, "Mitjans de comunicació"], [11, "Agricultura i pesca"], [12, "Venta al públic"], [13, "Negocis"], [14, "Grup empresarial"], [15, "Altres"]],
      liabilities: [[0, "No especificat"],[1, "Director ejecutiu"],[2, "Comandaments de segon nivell (dirigeixes a altres directius)"],[3, "Comandaments de primer nivell (dirigeixes a plantilla no directiva)"],[4, "Altres comandaments (amb persones al teu càrrec)"],[5, "Responsable de projecte/Project Manager (sense equip al teu cèrrec)"], [6, "No directiu (sense persones ni projectos al teu càrrec)"]],
      liabilitys: [[0, "No especificat"],[1, "Director ejecutiu"],[2, "Comandaments de segon nivell (dirigeixes a altres directius)"],[3, "Comandaments de primer nivell (dirigeixes a plantilla no directiva)"],[4, "Altres comandaments (amb persones al teu càrrec)"],[5, "Responsable de projecte/Project Manager (sense equip al teu cèrrec)"], [6, "No directiu (sense persones ni projectos al teu càrrec)"]],
      job_positions: [[0, "No especificat"], [1, "Administratiu"], [2, "Serveis profesionals"], [3, "Ingeniería / tècnic", "Finances"], [4, "Recursos Humans"], [5, "Tecnologies de la informació"], [6, "Operacions"], [7, "Marketing"], [8, "Logística i distribució"], [9, "Producció"], [10, "Compres"], [11, "Qualitat"], [12, "Investigació i desenvolupament"], [13, "Ventes"], [14, "Seguretat"], [15, "Altres"]],
      trainings: [[0, "No especificat"], [1, "Sense quealificació formal"], [2, "Estudis elementals"], [3, "ESO, Graduat escolar"], [4, "Batxiller, BUP, COU, FP, Graus professionales"], [5, "Licenciat, Diplomat, Grau universitari"], [6, "Postgrau (Master, Doctorat)"], [7, "Altres"]],
      mahou_births: [[0, "Menos de 30"], [1, "30-35"], [2, "36-45"], [3, "46-55"], [4, "Más de 56"]],
      mahou_antics: [[0, "0-2 años"], [1, "Más de 2 y menos de 5 años"], [2, "Más de 5 y menos de 10 años"], [3, "Más de 10 y menos de 15 años"], [4, "Más de 15 años"]],
      mahou_job_positions: [[0, "Directivos"], [1, "Mandos de Proximidad"], [2, "Mandos Intermedios"], [3, "Gestor"], [4, "Gestor Comercial"], [5, "Técnico"], [6, "Coordinador"], [7, "Administrativo"], [8, "Oficiales"], [9, "Chofer/ Repartidor"]],
      mahou_unitys: [[0, "Dirección Corporativa"], [1, "Distribución Propia"], [2, "Servicios Logísticos"], [3, "Unidad de Negocio Agua"], [4, "Unidad de Negocio España"], [5, "Unidad de Negocio Internacional"]]      
    }
   },
  pt: {
    header: {
      close_session: "Fechar Sessão",
      profile: "Perfil",
      surveys: "Evidência",
      proctoring: "Supervisão",
      close_session_modal: "Tem certeza que deseja sair?"
    },
    signin:{
      title: "Bem-vindo(a)!",
      title_backoffice: "T3 - BackOffice",
      subtitle: "Desejamos boas-vindas <br />  a plataforma de avaliação <span class='c-highlighted'> GSG Metrics.</span>",
      subtitle_backoffice: "Desejamos boas-vindas ao BackOffice <br />a plataforma de avaliação <span class='c-highlighted'> GSG Metrics.</span>",
      forgot_password: "Esqueceu sua senha?",
      forgot_password_title: "Recuperar senha",
      email_sent: "Uma mensagem foi enviada para o seu e-mail",
      email_not_found: "O e-mail não existe",
      change_password: "Alterar a senha"
    },    
    footer: {
      technical_problems: "Problemas técnicos? Entre em contato connosco",
      legal_advice: "Aviso legal",
      lgdp: "Proteção de dados",
    },        
    survey: {
      loading: "Loading...",
      title: "Evidência",
      modules: "Módulos",
      in_progress: "Em andamento",
      finished: "Finalizada",
      finished_a: "Finalizada",
      finished_thx: "Finalizada, obrigada/o!",
      closed: "Fechado",
      download_report: "Baixar relatório",
      show_report: "Ver relatório",
      progress: "Progresso",
      module_finished_title: "Continue assim!",
      module_finished_subtitle: "Você terminou",
      survey_finished_title: "Genial!",
      survey_finished_subtitle: "Finalizado!",
      time_ended: "Não há mais tempo disponível, por favor, clique na seta de 'próximo' para continuar.",
      warning: "AVISO",
      time_left: " Restam %time% para terminar o teste.",
      remember_button: 'Lembre-se de clicar no botão de enviar <span style="vertical-align: middle"><Arrow width=20 height=20/></span> <span class="o-color--red"><b>antes</b> que o tempo acabe.</span>',
      remember_lost: "Caso contrário, a sua resposta não será salva.",
      languages: "Linguas"      
    },
    buttons:{
      start: "Iniciar",
      back: "Voltar",
      continue: "Continuar",
      send: "Enviar",
      cancel: "Cancelar",
      accept: "Aceitar",
      check: "Verificar",
      refresh: "Recarregar"
    },  
    errors:{
      unexpected: "Ocorreu um erro",
    },
    profile: {
      title: "Perfil",
      subtitle: "A partir daqui você pode atualizar seus dados ou sair",
      success_title: "Atualizado com sucesso!"
    },
    form: {
      password: "Crie uma senha",
      password_confirmation: "Repita a nova senha",
      password_length: "Deve ter pelo menos 8 caracteres.",
      password_mismatch: "As senhas não correspondem.",
      empty: "Este campo não pode ser vazio",
      email: "E-mail", 
      email_not_valid: "E-mail inválido",
      email_not_changable: "Você não pode alterar seu e-mail.",    
      name: "Nome",
      surname: "Sobrenome",
      lastname: "Sobrenome",
      subject_id: "Subject ID",
      birthdate: "Ano de nascimento",
      gender: "Gênero",
      country: "País de residência",
      language: "Idioma",
      training: "Formação",
      job_position: "Trabalho",
      personal_identifier: "NIF",
      department: "Departamento",
      liability: "Responsabilidades da administração",
      sector: "Setor da organização",
      some_data: "Alguns dados",
      know_you: "Antes de começarmos, precisamos conhecê-lo(a) um pouco melhor:",
      process_password_info: "Por favor, mantenha o nome de usuário (e-mail) e a senha com a qual você se cadastrou na plataforma, pois será comum a todos os processos",
      language_cat: "Catalão",
      language_es: "Espanhol",
      language_en: "Inglês",
      language_pt: "Português",
      language_de: "Deutsch",
      language_hr: "Croatian",      
      gender_men: "Homem",
      gender_women: "Mulher",
      gender_other: "Outro",
      gender_0: "Homem",
      gender_1: "Mulher",
      gender_2: "Outro",
      mahou_birth: "Edad",
      mahou_antic: "Antigüedad",
      mahou_job_position: "Perfil de puesto",
      mahou_unity: "Unidad de Negocio/Dirección"      
    },
    invitation: {
      expired: "Convite expirado/inválido",
      expired_description: "Solicite um novo convite por correio electrónico para continuar o processo.",
      already_accepted: "Convite já aceite",
      already_accepted_description: "O utilizador já existe, por favor inicie sessão com o nome de utilizador e a palavra-passe que já definiu."
    },
    proctoring:{
      error_title: "Parece que tivemos um problema. Verifique as permissões ou instruções em seu navegador e tente novamente.",      
      start: {
        title: "Vamos fazer alguns testes técnicos!",
        description: "Com esses testes, verificaremos se seu sistema atende aos requisitos técnicos<br/>Verificaremos se a câmera e o microfone funcionam corretamente verificando se a imagem e o som são recebidos.<br/>Também realizaremos uma verificação de compartilhamento de tela.<br/>Por favor, pressione o botão continuar e nós guiaremos em cada etapa.",
        browsers_compatibility: "Os <b>navegadores compatíveis</b>para fiscalização são",
        title_inside: "Opa! Este teste requer supervisão remota",
        description_inside: "Antes de iniciar o teste, verificaremos se o seu sistema atende a todos os requisitos técnicos necessários.<br/><br/>Por favor, pressione o botão continuar e nós guiaremos pela verificação"
      },
      camera_speakers: {
        title: "Câmera e microfone", 
        description: "Vamos verificar se a câmera e o microfone estão funcionando corretamente.<br/><br/>Para verificar se recebemos imagem e som, aceite as permissões para usar a câmera e o microfone assim que pressionar o botão de verificação.",
        success: "Câmera e microfone, ok!"
      },
      screen_recording: {
        title: "Gravação de tela", 
        description: "Em seguida, verificaremos se você pode compartilhar a tela conosco.<br/><br/>Por favor, pressione o botão Verificar e se você tiver mais de uma tela, selecione aquela em que você vai realizar o teste e espere até receber a mensagem de verificação.",
        success: "Gravação de tela, ok!"
      },
      success: {
        title: "Perfeito, tudo ok.",
        description: "Parabéns, seu sistema atende a todos os requisitos técnicos necessários para realizar a supervisão remota.<br/><br/>Por favor, não faça nenhuma alteração até que você tenha testado.",
        description_inside: "Acabamos de verificar que seu sistema atende adequadamente aos requisitos de monitoramento remoto.<br/><br/>Por favor, quando estiver pronto, pressione o botão Iniciar para iniciar o teste.",
      },
      permissions: {
        title: "Por favor, ative as permissões",
        description: "Parece que não foi possível ativar os serviços, talvez seja necessário conceder permissões ao navegador na seção de segurança das configurações do sistema"
      }
    },
    dictionaries:{
      languages: [["es", "Espanhol"], ["en", "Inglês"], ["cat", "Catalão"], ["pt", "Português"], ["de", "Deutsch"], ["hr", "Croatian"]],
      genders: [[0, "Homem"], [1, "Mulher"], [2, "Outro"]],
      sectors: [[0, "Não especificado"], [1, "Indústria"], [2, "Empresas de serviços"], [3, "Administração pública"], [4, "Petróleo, gás ou mineração"], [5, "Farmacêutica ou de saúde"], [6, "Banco e finanças"], [7, "Transporte e distribuição"], [8, "Tecnologias da informação"], [9, "Telecomunicações"], [10, "Meios de comunicação"], [11, "Agricultura e pesca"], [12, "Venda ao público"], [13, "Negócios"], [14, "Grupo empresarial"], [15, "Outros"]],
      liabilities: [[0, "Não especificado"],[1, "Diretor executivo"],[2, "Gerentes de segundo nível (você dirige outros gerentes)"],[3, "Gerentes de primeiro nível (você dirige uma equipe não gerencial)"],[4, "Outros controles (com pessoas sob sua responsabilidade)"],[5, "Gerente de Projetos/Gerente de Projetos (sem equipe a seu cargo)"], [6, "Não gerencial (sem pessoas ou projetos a seu cargo)"]],
      liabilitys: [[0, "Não especificado"],[1, "Diretor executivo"],[2, "Gerentes de segundo nível (você dirige outros gerentes)"],[3, "Gerentes de primeiro nível (você dirige uma equipe não gerencial)"],[4, "Outros controles (com pessoas sob sua responsabilidade)"],[5, "Gerente de Projetos/Gerente de Projetos (sem equipe a seu cargo)"], [6, "Não gerencial (sem pessoas ou projetos a seu cargo)"]],
      job_positions: [[0, "Não especificado"], [1, "Administrativo"], [2, "Serviços profissionais"], [3, "Engenharia / técnico", "Finanças"], [4, "Recursos Humanos"], [5, "Tecnologias da informação"], [6, "Operações"], [7, "Marketing"], [8, "Logística e distribuição"], [9, "Produção"], [10, "Compras"], [11, "Qualidade"], [12, "Investigação e desenvolvimento"], [13, "Vendas"], [14, "Segurança"], [15, "Outros"]],
      trainings: [[0, "No especificado"], [1, "Sem qualificação formal"], [2, "Estudos elementares"], [3, "Ensino Fundamental 2"], [4, "Ensino Médio, ensino profissional"], [5, "Graduação"], [6, "Pós-Graduação, MBA, Doutorado"], [7, "Outros"]],
      mahou_births: [[0, "Menos de 30"], [1, "30-35"], [2, "36-45"], [3, "46-55"], [4, "Más de 56"]],
      mahou_antics: [[0, "0-2 años"], [1, "Más de 2 y menos de 5 años"], [2, "Más de 5 y menos de 10 años"], [3, "Más de 10 y menos de 15 años"], [4, "Más de 15 años"]],
      mahou_job_positions: [[0, "Directivos"], [1, "Mandos de Proximidad"], [2, "Mandos Intermedios"], [3, "Gestor"], [4, "Gestor Comercial"], [5, "Técnico"], [6, "Coordinador"], [7, "Administrativo"], [8, "Oficiales"], [9, "Chofer/ Repartidor"]],
      mahou_unitys: [[0, "Dirección Corporativa"], [1, "Distribución Propia"], [2, "Servicios Logísticos"], [3, "Unidad de Negocio Agua"], [4, "Unidad de Negocio España"], [5, "Unidad de Negocio Internacional"]]      
    }
  },
  de:{
    header: {
      close_session: "Sitzung schließen",
      profile: "Profil",
      surveys: "Umfragen",
      proctoring: "Aufsicht",
      close_session_modal: "Sind Sie sicher, dass Sie die Sitzung schließen wollen?"
    },
    footer: {
      technical_problems: "Technische Probleme? Kontaktieren Sie uns",
      legal_advice: "Rechtlicher Hinweis",
      lgdp: "Datenschutz"
    },
    signin:{
      title: "Herzlich willkommen!",
      title_backoffice: "T3 - BackOffice",
      subtitle: "Wir begrüßen Sie auf <br /> der <span class = 'c-highlighted'> GSG Metrics</span>-Bewertungsplattform.",
      subtitle_backoffice: "Wir begrüßen Sie auf <br /> der <span class = 'c-highlighted'> GSG Metrics</span>-Backoffice-Bewertungsplattform.",
      forgot_password: "Passwort vergessen?",
      rescue_password: "Passwort wiederherstellen",
      email_sent: "Eine E-Mail wurde an Ihren Posteingang gesendet",
      email_not_found: "E-Mail nicht gefunden",
      change_password: "Kennwort ändern"
    },
    survey: {
      loading: "Wird geladen...",
      title: "Umfragen",
      modules: "Module",
      in_progress: "Laufend",
      finished: "Beendet",
      finished_a: "Beendet",
      finished_thx: "Beendet, danke!",
      closed: "Abgeschlossen",
      download_report: "Bericht herunterladen",
      show_report: "Bericht anzeigen",
      progress: "Fortschritt",
      module_finished_title: "Weitermachen!",
      module_finished_subtitle: "Sie haben dieses Modul abgeschlossen",
      survey_finished_title: "Sehr gut!",
      survey_finished_subtitle: "Umfrage beendet!",
      time_ended: "Es ist keine Zeit mehr, um fortzufahren, klicken Sie bitte auf den nächsten Pfeil",
      warning: "WARNUNG",
      time_left: "Sie haben noch %time% Zeit, um den Test abzuschließen.",
      remember_button: 'Denken Sie daran, dass Sie auf die Schaltfläche Senden klicken müssen <span style="vertical-align: middle"><Arrow width=20 height=20/></span> <span class="o-color--red"> <b>bevor</b> die Zeit abläuft</span>',
      remember_lost: "Wenn nicht, wird Ihre Antwort nicht gespeichert",
      languages: "Sprachen"            
    },
    buttons:{
      start: "Starten",
      back: "Zurück",
      continue: "Weiter",
      send: "Senden",
      cancel: "Abbrechen",
      accept: "Akzeptieren",
      check: "Überprüfen",
      refresh: "Aktualisieren"
    },
    errors:{
      unexpected: "Es ist ein Fehler aufgetreten",
    },
    profile: {
      title: "Profil",
      subtitle: "Sie können Ihre persönlichen Daten aktualisieren oder die Sitzung schließen",
      success_title: "Erfolgreich aktualisiert!"
    },   
    form: {
      password: "Passwort",
      password_confirmation: "Bestätigung des Passworts",
      password_length: "Sollte mindestens 8 Zeichen lang sein",
      password_mismatch: "Passwörter stimmen nicht überein",
      empty: "Darf nicht leer sein",
      name: "Name",
      email: "E-Mail", 
      email_not_valid: "E-Mail nicht gültig",
      email_not_changable: "Sie können die E-Mail nicht ändern",
      surname: "Familienname",
      lastname: "Nachname",
      subject_id: "Subject ID",
      birthdate: "Geburtsdatum",
      gender: "Geschlecht",
      country: "Land",
      language: "Sprache",
      training: "Ausbildung",
      job_position: "Berufliche Position",
      personal_identifier: "Nummer des Personalausweises",      
      department: "Abteilung",
      liability: "Verantwortlichkeiten des Managements",
      sector: "Sektor der Organisation",
      some_data: "Einige Daten",
      know_you: "Bevor wir beginnen, müssen wir Sie ein wenig besser kennen lernen:",
      process_password_info: "Bitte bewahren Sie den Benutzernamen und das Passwort auf, mit denen Sie sich auf der Plattform registriert haben, da sie für alle Vorgänge verwendet werden",
      language_es: "Spanisch",
      language_en: "Englisch",
      language_cat: "Katalanisch",
      language_pt: "Portugiesisch",
      language_de: "Deutsch",
      language_hr: "Kroatisch",      
      gender_men: "Mann",
      gender_women: "Frau",
      gender_other: "Anderes",
      gender_0: "Mann",
      gender_1: "Frau",
      gender_2: "Anderes",
      mahou_birth: "Alter",
      mahou_antic: "Dienstalter",
      mahou_job_position: "Berufliches Profil",
      mahou_unity: "Geschäftseinheit/Management"

    },
    proctoring:{
      success_description: "Wir haben soeben festgestellt, dass Ihr System die Anforderungen für die Fernkontrolle erfüllt. <br/> <br/> Wenn Sie bereit sind, drücken Sie bitte die Schaltfläche Start, um den Test zu starten.",
      error_title: "Es scheint ein Problem aufgetreten zu sein, bitte überprüfen Sie die Berechtigungen oder Anweisungen in Ihrem Browser und versuchen Sie es erneut",
      start: {
        title: "Wir werden nun einige technische Tests durchführen!",
        description: "Mit diesen Tests werden wir überprüfen, ob Ihr System die technischen Anforderungen erfüllt. <br/> Wir überprüfen, ob die Kamera und das Mikrofon richtig funktionieren, und kontrollieren, ob Bild und Ton empfangen werden. <br/> Wir führen auch eine Überprüfung der Bildschirmfreigabe durch. <br/> Bitte klicken Sie auf die Schaltfläche 'Weiter' und wir werden Sie durch die einzelnen Schritte führen.",
        browsers_compatibility: "Die <b> unterstützten Browser </b> für die Aufsicht sind",
        title_inside: "Huch! Dieser Test erfordert eine Fernüberwachung",
        description_inside: "Bevor wir mit dem Test beginnen, werden wir überprüfen, ob Ihr System alle notwendigen technischen Voraussetzungen erfüllt. <br/> <br/> Bitte klicken Sie auf die Schaltfläche 'Weiter' und wir werden Sie durch die Prüfung führen"        
      },
      camera_speakers: {
        title: "Kamera und Mikrofon",
        description: "Wir prüfen, ob Ihre Kamera und Ihr Mikrofon richtig funktionieren. <br/> <br/> Um zu überprüfen, ob wir Bild und Ton empfangen, akzeptieren Sie bitte die Erlaubnis zur Verwendung von Kamera und Mikrofon, sobald Sie auf die Schaltfläche 'Prüfen' klicken",
        success: "Kamera und Mikrofon, ok!"
      },
      screen_recording: {
        title: "Bildschirmaufnahme",
        description: "Als nächstes überprüfen wir, ob Sie den Bildschirm mit uns teilen können. <br/> <br/> Wenn Sie mehr als einen Bildschirm haben, wählen Sie aus, wo Sie den Test durchführen möchten, und warten Sie, bis Sie die Bestätigungsmeldung erhalten.",
        success: "Bildschirmaufnahme, ok!"
      },
      success: {
        title: "Perfekt!, alles in Ordnung.",
        description: "Herzlichen Glückwunsch, Ihr System erfüllt alle technischen Voraussetzungen, um eine Fernkontrolle durchzuführen. <br/> <br/> Bitte nehmen Sie keine Änderungen vor, bis Sie den Test durchgeführt haben.",
        description_inside: "Wir haben soeben festgestellt, dass Ihr System die Anforderungen für die Fernkontrolle erfüllt. <br/> <br/> Wenn Sie bereit sind, drücken Sie bitte die Schaltfläche Start, um den Test zu starten.",
      },
      permissions: {
        title: "Bitte aktivieren Sie die Berechtigungen",
        description: "Es scheint, dass wir die Dienste nicht aktivieren konnten. Vielleicht sollten Sie dem Browser im Abschnitt Sicherheit Ihrer Systemeinstellungen die entsprechenden Rechte erteilen"
      }      
    },    
    dictionaries:{
      languages: [["es", "Spanish"], ["en", "English"], ["cat", "Catalán"], ["pt", "Portugués"], ["de", "Deutsch"], ["hr", "Kroatisch"]],
      genders: [[0, "Man"], [1, "Women"], [2, "Other"]],
      sectors: [[0, "Unspecified"], [1, "Industry"], [2, "Service companies"], [3, "Public administration"], [4, "Oil, gas or mining"], [5, "Pharmaceutical or health care"], [6, "Banking and finance"], [7, "Transport and distribution"], [8, "Technologies of information "], [9, "Telecommunications "], [10, "Media "], [11, "Agriculture and fisheries "], [12, "Sales to the public"], [13, "Business"], [14, "Business group"], [15, "Others"]],
      liabilities: [[0, "Unspecified"],[1, "Executive Director"],[2, "Second level managers (you manage other managers)"],[3, "Top-level managers (you direct non-managerial staff)"],[4, "Other managers (with people in your charge)"],[5, "Project manager / Project Manager (without your team)"],[6, "Non-managerial (without people or projects in your charge)"]],
      liabilitys: [[0, "Unspecified"],[1, "Executive Director"],[2, "Second level managers (you manage other managers)"],[3, "Top-level managers (you direct non-managerial staff)"],[4, "Other managers (with people in your charge)"],[5, "Project manager / Project Manager (without your team)"],[6, "Non-managerial (without people or projects in your charge)"]],
      job_positions: [[0, "Unspecified"], [1, "Administrative"], [2, "Professional Services"], [3, "Engineering / Technician", "Finance"], [4, "Human Resources"], [5, "Information technology"], [6, "Operations"], [7, "Marketing"], [8, "Logistics and distribution"], [9, "Production"], [10, "Purchasing "], [11," Quality "], [12," Research and development "], [13," Sales "], [14," Safety "], [15," Others "]],
      trainings: [[0, "Unspecified"], [1, "No formal qualification"], [2, "Elementary studies"], [3, "ESO, School graduate"], [4, "Bachelor, BUP, COU, FP, Professional degrees "], [5," Graduate, Diploma, University degree "], [6," Postgraduate (Master, Doctorate) "], [7," Others "]],
      mahou_births: [[0, "Menos de 30"], [1, "30-35"], [2, "36-45"], [3, "46-55"], [4, "Más de 56"]],
      mahou_antics: [[0, "0-2 años"], [1, "Más de 2 y menos de 5 años"], [2, "Más de 5 y menos de 10 años"], [3, "Más de 10 y menos de 15 años"], [4, "Más de 15 años"]],
      mahou_job_positions: [[0, "Directivos"], [1, "Mandos de Proximidad"], [2, "Mandos Intermedios"], [3, "Gestor"], [4, "Gestor Comercial"], [5, "Técnico"], [6, "Coordinador"], [7, "Administrativo"], [8, "Oficiales"], [9, "Chofer/ Repartidor"]],
      mahou_unitys: [[0, "Dirección Corporativa"], [1, "Distribución Propia"], [2, "Servicios Logísticos"], [3, "Unidad de Negocio Agua"], [4, "Unidad de Negocio España"], [5, "Unidad de Negocio Internacional"]]      
    }   
  },
hr: {
  header: {
    close_session: "Zatvori",
    profile: "Profil",
    surveys: "Provjere",
    proctoring: "nadgledanje",
    close_session_modal: "Jesi li siguran/sigurna da želiš zatvoriti?"
  },
  signin:{
    title: "Dobrodošao!/Dobrodošla!",
    title_backoffice: "T3 - BackOffice",
    subtitle: "Dobrodošao/Dobrodošla na <br/> evaluacijsku platformu za <span class='c-highlighted'> GSG Metrics.</span>",
    subtitle_backoffice: "Dobrodošao/Dobrodošla u BackOffice na <br /> evaluacijsku platformu za <span class='c-highlighted'> GSG Metrics.</span>",
    forgot_password: "Jesi li zaboravio/zaboravila svoju lozinku?",
    forgot_password_title: "Obnovi lozinku",
    email_sent: "Poslan je e-mail na vašu adresu.",
    email_not_found: "E-mail ne postoji.",
    change_password: "Promijeni lozinku."
  },    
  footer: {
    technical_problems: "Imaš tehničkih problema? Javi nam se",
    legal_advice: "Pravno upozorenje",
    lgdp: "Zaštita podataka",
  },        
  survey: {
    loading: "Otvara se...",
    title: "Provjere",
    modules: "Moduli",
    in_progress: "U tijeku",
    finished: "Gotovo",
    finished_a: "Gotovo",
    finished_thx: "Gotovo, hvala!",
    closed: "Closed",
    download_report: "Preuzeti izvješće",
    show_report: "Vidjeti izvješće",
    progress: "Tijek",
    module_finished_title: "Samo tako nastavi!",
    module_finished_subtitle: "Završio/Završila si ovaj modul, sada možeš nastaviti sa sljedećim",
    survey_finished_title: "Izvrsno!",
    survey_finished_subtitle: "Provjera je gotova!",
    time_ended: "Ya no queda tiempo, para continuar por favor dale a la flecha de siguiente",
    warning: "AVISO",
    time_left: "Te quedan %time% para terminar la prueba.",
    remember_button: 'Recuerda que tienes que darle al botón de enviar <span style="vertical-align: middle"><Arrow width=20 height=20/></span> <span class="o-color--red"><b>antes</b> de que termine el tiempo</span>',
    remember_lost: "Si no tu respuesta no será guardada",
    languages: "Jezici"
  },
  buttons:{
    start: "Počni",
    back: "Vrati se",
    continue: "Nastavi",
    send: "Pošalji",
    cancel: "Otkaži",
    accept: "Prihvati",
    check: "Provjeri",
    refresh: "Učitaj ponovno"
  },  
  errors:{
    unexpected: "Pojavila se pogreška",
  },
  profile: {
    title: "Profil",
    subtitle: "Ovdje možeš ažurirati svoje podatke ili zatvoriti.",
    success_title: "Uspješno ažuriranje!"
  },
  form: {
    password: "Lozinka",
    password_confirmation: "Potvrdi lozinku",
    password_length: "Minimalno 8 znakova.",
    password_mismatch: "Lozinke se ne podudaraju.",
    empty: "Ovo polje ne može ostati prazno",
    email: "E-mail", 
    email_not_valid: "E-mail nije ispravan",
    email_not_changable: "Ne možeš promijeniti svoj e-mail.",    
    name: "Ime",
    surname: "Prvo prezime",
    lastname: "Drugo prezime",
    subject_id: "Subject ID",
    birthdate: "Godina rođenja",
    gender: "Rod",
    country: "Zemlja prebivališta",
    language: "Jezik",
    training: "Obrazovanje",
    job_position: "Posao",
    personal_identifier: "Number of personal identification",    
    department: "Odjel",
    liability: "Menadžerska odgovornost",
    sector: "Organizacijski sektor",
    some_data: "Ostali podaci",
    know_you: "Prije nego što počnemo, moramo te upoznati malo bolje:",
    process_password_info: "Molimo da sačuvaš korisničko ime (e-mail) i lozinku s kojima si se registrirao/registrirala na platformu jer će se koristiti tijekom cijelog procesa",
    language_es: "Španjolski",
    language_en: "Engleski",
    language_cat: "Katalonski",
    language_pt: "Portuglaski",
    language_de: "Njemački",
    language_hr: "Hrvatski",
    gender_men: "Muško",
    gender_women: "Žensko",
    gender_other: "Ostalo",
    gender_0: "Muško",
    gender_1: "Žensko",
    gender_2: "Ostalo",
    mahou_birth: "Dob",
    mahou_antic: "Staž",
    mahou_job_position: "Profil radnog mjesta",
    mahou_unity: "Poslovna jedinica/Uprava"

  },
  proctoring:{
    error_title: "Čini se da se pojavio problem, molimo da provjeriš dopuštenja ili upute na svom pregledniku i probaš ponovno",      
    start: {
      title: "Napravit ćemo nekoliko tehničkih provjera!",
      description: "S ovim ćemo provjerama utvrditi da je tvoj sustav usklađen s tehničkim zahtjevima <br/>. Provjerit ćemo funkcioniraju li kamera i mikrofon tako što ćemo vidjeti prima li se slika i zvuk. <br/> Napravit ćemo i provjeru dijeljenja ekrana. <br/> Molimo, stisni tipku Nastavi i vodit ćemo te na svakom koraku",
      browsers_compatibility: "Kompatibilni preglednici za nadgledanje su",
      title_inside: "Ups! Ova provjera zahtjeva nadzor na daljinu",
      description_inside: "Prije nego što počnemo s provjerom, utvrdit ćemo je li tvoj sustav usklađen sa svim potrebnim tehničkim zahtjevima.<br/><br/> Molimo da stistneš Nastavi i vodit ćemo te tijekom provjere"
    },
    camera_speakers: {
      title: "Kamera i mikrofon", 
      description: "Provjerit ćemo funkcioniraju li ispravno tvoja kamera i tvoj mikrofon. <br/><br/> Kako bismo utvrdili da primamo sliku i zvuk, molimo da prihvatiš dozvole za korištenje kamere i mikrofona tako što ćeš stisnuti Prihvati",
      success: "Kamera i mikrofon, u redu!"
    },
    screen_recording: {
      title: "Snimanje ekrana", 
      description: "U nastavku ćemo provjeriti možeš li dijeliti ekran s nama. <br/><br/> Molimo da stisneš tipku Provjeri i u slučaju da imaš više od jednog ekrana, odaberi onaj preko kojeg ćeš izvršiti provjeru i pričekaj dok ne primiš verifikacijsku poruku",
      success: "Snimanje ekrana, u redu!"
    },
    success: {
      title: "Savršeno, sve je u redu!",
      description: "Čestitamo, tvoj sustav ispunjava sve potrebne tehničke zahtjeve za izvršavanje nadzora na daljinu. <br/><br/< Molimo da ništa ne mijenjaš dok ne završiš s provjerom.",
      description_inside: "Upravo smo utvrdili da tvoj sustav savršeno ispunjava sve potrebne zahtjeve za izvršavanje nadzora na daljinu. <br/><br/> Molimo da stisneš tipku Počni kad si spreman započeti s provjerom.",
    },
    permissions: {
      title: "Molimo da aktiviraš dopuštenja",
      description: "Čini se da nismo uspjeli aktivirati usluge, možda treba dati dopuštenja pregledniku unutar sigurnosnih postavki tvog sustava"
    }
  },
  dictionaries:{
    languages: [["es", "Español"], ["en", "Inglés"], ["cat", "Català"], ["pt", "Portugués"], ["de", "Alemán"], ["hr", "Croatian"]],
    genders: [[0, "Hombre"], [1, "Mujer"], [2, "Otro"]],
    sectors: [[0, "No especificado"], [1, "Industria"], [2, "Empresas de servicio"], [3, "Administración pública"], [4, "Petroleras, gas o minería"], [5, "Farmacéutica o de asistencia sanitaria"], [6, "Banca y finanzas"], [7, "Transportes y distribución"], [8, "Tecnologías de la información"], [9, "Telecomunicaciones"], [10, "Medios de comunicación"], [11, "Agricultura y pesca"], [12, "Venta al público"], [13, "Negocios"], [14, "Grupo empresarial"], [15, "Otros"]],
    liabilities: [[0, "No especificado"],[1, "Director ejecutivo"],[2, "Mandos de segundo nivel (diriges a otros directivos)"],[3, "Mandos de primer nivel (diriges a plantilla no directiva)"],[4, "Otros mandos (con personas a tu cargo)"],[5, "Responsable de proyecto/Project Manager (sin equipo atu cargo)"], [6, "No directivo (sin personas ni proyectos a tu cargo)"]],
    liabilitys: [[0, "No especificado"],[1, "Director ejecutivo"],[2, "Mandos de segundo nivel (diriges a otros directivos)"],[3, "Mandos de primer nivel (diriges a plantilla no directiva)"],[4, "Otros mandos (con personas a tu cargo)"],[5, "Responsable de proyecto/Project Manager (sin equipo atu cargo)"], [6, "No directivo (sin personas ni proyectos a tu cargo)"]],
    job_positions: [[0, "No especificado"], [1, "Administrativo"], [2, "Servicios profesionales"], [3, "Ingeniería / técnico", "Finanzas"], [4, "Recursos Humanos"], [5, "Tecnologías de la información"], [6, "Operaciones"], [7, "Marketing"], [8, "Logística y distribución"], [9, "Producción"], [10, "Compras"], [11, "Calidad"], [12, "Investigación y desarrollo"], [13, "Ventas"], [14, "Seguridad"], [15, "Otros"]],
    trainings: [[0, "No especificado"], [1, "Sin cualificación formal"], [2, "Estudios elementales"], [3, "ESO, Graduado escolar"], [4, "Bachiller, BUP, COU, FP, Grados profesionales"], [5, "Licenciado, Diplomado, Grado universitario"], [6, "Postgrado (Master, Doctorado)"], [7, "Otros"]],
    mahou_births: [[0, "Menos de 30"], [1, "30-35"], [2, "36-45"], [3, "46-55"], [4, "Más de 56"]],
    mahou_antics: [[0, "0-2 años"], [1, "Más de 2 y menos de 5 años"], [2, "Más de 5 y menos de 10 años"], [3, "Más de 10 y menos de 15 años"], [4, "Más de 15 años"]],
    mahou_job_positions: [[0, "Directivos"], [1, "Mandos de Proximidad"], [2, "Mandos Intermedios"], [3, "Gestor"], [4, "Gestor Comercial"], [5, "Técnico"], [6, "Coordinador"], [7, "Administrativo"], [8, "Oficiales"], [9, "Chofer/ Repartidor"]],
    mahou_unitys: [[0, "Dirección Corporativa"], [1, "Distribución Propia"], [2, "Servicios Logísticos"], [3, "Unidad de Negocio Agua"], [4, "Unidad de Negocio España"], [5, "Unidad de Negocio Internacional"]]
  }
 },  
});

export default strings; 
